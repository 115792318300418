export const openWindows = async (imagePaths: any) => {
    let pathsArray: string[];

    const checkFileType = async (paths: string[]): Promise<any[]> => {
        const requests = paths.map(url => fetch(url, {method: 'HEAD'}));

        try {
            const responses = await Promise.all(requests);
            return responses.map(resp => ({
                url: resp.url,
                type: resp.headers.get('Content-Type')
            }));
        } catch (error) {
            console.error("Failed to fetch image types:", error);
            return [];
        }
    }

    if (typeof imagePaths === 'string') {
        try {
            pathsArray = JSON.parse(imagePaths);
            if (!Array.isArray(pathsArray)) {
                throw new Error("Parsed value is not an array");
            }
        } catch (error) {
            pathsArray = [imagePaths];
        }
    } else if (Array.isArray(imagePaths)) {
        pathsArray = imagePaths;
    } else {
        console.error("Invalid image paths format.");
        return;
    }

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 8;
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    const prefixedPathsArray = pathsArray.map(path => "https://hrms21.s3.ap-southeast-1.amazonaws.com/" + path + "?s=" + result);
    const fileTypeInfo = await checkFileType(prefixedPathsArray);

    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <title>Attachments</title>
        <style>
            body {
                margin: 0;
                padding: 0;
                font-family: Arial, sans-serif;
                background-color: #f8f9fa;
                display: flex;
                flex-direction: column;
                height: 100vh;
            }
    
            #viewer {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: auto;
                padding: 10px;
            }
    
            iframe, img {
                max-width: 100%;
                max-height: 90vh;
                border: none;
            }
    
            #navControls {
                display: flex;
                justify-content: center;
                padding: 10px;
                background: #eee;
                gap: 20px;
            }
    
            button {
                padding: 5px 15px;
                font-size: 16px;
                cursor: pointer;
            }
        </style>
    </head>
    <body>
        <div id="viewer"></div>
        <div id="navControls">
            <button id="prevBtn">Previous</button>
            <span id="counter">1 / 1</span>
            <button id="nextBtn">Next</button>
        </div>
    
        <script>
            const attachments = ${JSON.stringify(fileTypeInfo)};
            let currentIndex = 0;
    
            function renderAttachment(index) {
                const container = document.getElementById('viewer');
                const counter = document.getElementById('counter');
                container.innerHTML = '';
    
                const item = attachments[index];
                if (!item) return;
    
                if (item.type === 'application/pdf') {
                    const iframe = document.createElement('iframe');
                    iframe.src = item.url;
                    iframe.width = '100%';
                    iframe.height = '100%';
                    container.appendChild(iframe);
                } else if (item.type.startsWith('image/')) {
                    const img = document.createElement('img');
                    img.src = item.url;
                    container.appendChild(img);
                }
    
                counter.textContent = (index + 1) + ' / ' + attachments.length;
            }
    
            document.getElementById('prevBtn').addEventListener('click', () => {
                if (currentIndex > 0) {
                    currentIndex--;
                    renderAttachment(currentIndex);
                }
            });
    
            document.getElementById('nextBtn').addEventListener('click', () => {
                if (currentIndex < attachments.length - 1) {
                    currentIndex++;
                    renderAttachment(currentIndex);
                }
            });
    
            // Init
            renderAttachment(currentIndex);
        </script>
    </body>
    </html>
    `;


    const newWindow = window.open(
        '',
        '_blank',
        `scrollbars=yes,
        resizable=yes,
        status=no,
        location=no,
        toolbar=no,
        menubar=no,
        height=1600,
        width=1200,
        top=100`);
    if (newWindow) {
        newWindow.document.write(htmlContent);
        newWindow.document.close();
    } else {
        console.error("Failed to open new window.");
    }
}
