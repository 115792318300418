import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {financeRouters} from "@/router/finance";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home page",
        component: () => import("@/views/home/HomePage.vue"),
        meta: {
            nonAuth: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
        meta: {
            nonAuth: true,
        },
    },
    {
        path: "/terms",
        name: "terms",
        component: () => import("@/views/TermCondition.vue"),
        meta: {
            nonAuth: true,
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: () => import("@/views/dashboard/Dashboard.vue")
            },
            {
                path: '/employee',
                name: 'Employee Management',
                component: () => import("@/views/staff/EmployeeList.vue")
            },
            {
                path: '/adHoc',
                name: 'Ad Hoc Management',
                component: () => import("@/views/staff/AdHocList.vue")
            },
            {
                path: '/blacklist',
                name: 'Employee Blacklist Management',
                component: () => import("@/views/staff/EmployeeBlacklist.vue")
            },
            {
                path: '/mail',
                name: 'Employee Mail Notification',
                component: () => import("@/views/staff/MailNotificationInfo.vue")
            },
            {
                path: '/notice',
                name: 'Employee Notice',
                component: () => import("@/views/staff/NoticeList.vue")
            },
            {
                path: '/workPlan',
                name: 'Work Plan Management',
                component: () => import("@/views/roster/ProjectRosterWeekly.vue")
            },
            {
                path: '/vendor',
                name: 'Vendor',
                component: () => import("@/views/procurement/vendor/VendorList.vue")
            },
            {
                path: '/contract',
                name: 'Contract',
                component: () => import("@/views/procurement/contract/ContractList.vue")
            },
            {
                path: '/attendance',
                name: 'Attendance List',
                component: () => import("@/views/attendance/AttendanceList.vue")
            },
            {
                path: '/attendance/security',
                name: 'Security Attendance Records',
                component: () => import("@/views/attendance/SecurityAttendanceList.vue")
            },
            {
                path: '/company',
                name: 'Company Setup',
                component: () => import("@/views/setup/admin/CompanySetup.vue")
            },
            {
                path: '/admin',
                name: 'User Setup',
                component: () => import("@/views/setup/admin/UserSetup.vue")
            },
            {
                path: '/role',
                name: 'Role Setup',
                component: () => import("@/views/setup/admin/SysRolesSetup.vue")
            },
            {
                path: '/accessLevel',
                name: 'Access Level Setup',
                component: () => import("@/views/setup/admin/SysAccessLevelSetup.vue")
            },
            {
                path: '/permission',
                name: 'Permission Setup',
                component: () => import("@/views/setup/admin/SysPermissionSetup.vue")
            },
            {
                path: '/setup/basic',
                name: 'Basic Details Setup',
                component: () => import("@/views/setup/BasicDetailsSetup.vue")
            },
            {
                path: '/setup/attendance',
                name: 'Attendance Details Setup',
                component: () => import("@/views/setup/attendance/AttendanceDetailsSetup.vue")
            },
            {
                path: '/setup/shift',
                name: 'Shift Schedule Setup',
                component: () => import("@/views/setup/ShiftScheduleSetup.vue")
            },
            {
                path: '/setup/leave',
                name: 'Leave Details Setup',
                component: () => import("@/views/setup/leave/LeaveDetailsSetup.vue")
            },
            {
                path: '/setup/claim',
                name: 'Claim Details Setup',
                component: () => import("@/views/setup/claim/ClaimDetailsSetup.vue")
            },
            {
                path: '/setup/salaryType',
                name: 'Salary Type Setup',
                component: () => import("@/views/setup/salary/SalaryAllowanceDetails.vue")
            },
            {
                path: '/setup/taxRate',
                name: 'Tax Rate Setup',
                component: () => import("@/views/setup/accounting/TaxRateList.vue")
            },
            {
                path: '/setup/currency',
                name: 'Currency Setup',
                component: () => import("@/views/setup/accounting/CurrencyList.vue")
            },
            {
                path: '/setup/holiday',
                name: 'Holiday Details Setup',
                component: () => import("@/views/setup/HolidaySetup.vue")
            },
            {
                path: '/initialize',
                name: 'Payroll Initialization',
                component: () => import("@/views/payroll/PayrollInitialization.vue")
            },
            {
                path: '/payroll/list',
                name: 'Payroll List',
                component: () => import("@/views/payroll/PayrollList.vue")
            },
            {
                path: '/adjustment',
                name: 'Salary Adjustment',
                component: () => import("@/views/payroll/SalaryAdjustment.vue")
            },
            {
                path: '/payroll/MonthlySummary',
                name: 'Monthly Summary',
                component: () => import("@/views/payroll/MonthlySummary.vue")
            },
            {
                path: '/incomeTax/initialize',
                name: 'IR8A Initialization',
                component: () => import("@/views/payroll/ir8a/IR8AInitialization.vue")
            },
            {
                path: '/leave',
                name: 'Leave Records',
                component: () => import("@/views/leave/LeaveReview.vue")
            },
            {
                path: '/leave/encash',
                name: 'Encash Leave Management',
                component: () => import("@/views/leave/EncashLeaveRecords.vue")
            },
            {
                path: '/leave/carried',
                name: 'Carried Forward Leave Management',
                component: () => import("@/views/leave/CarriedForwardLeave.vue")
            },
            {
                path: '/leave/balance',
                name: 'Leave Balance',
                component: () => import("@/views/leave/LeaveBalance.vue")
            },
            {
                path: '/leave/oiu',
                name: 'Off-In-Lieu Leave',
                component: () => import("@/views/leave/OffInLieuLeave.vue")
            },
            {
                path: '/leave/proxyLeave',
                name: 'Proxy Leave',
                component: () => import("@/views/leave/ProxyLeave.vue")
            },
            {
                path: '/claim',
                name: 'Claim Records',
                component: () => import("@/views/claim/ClaimRecords.vue")
            },
            {
                path: '/claim/balance',
                name: 'Claim Balance',
                component: () => import("@/views/claim/ClaimBalance.vue")
            },
            {
                path: '/serviceFee',
                name: 'Service Fee Claim Records',
                component: () => import("@/views/claim/ServiceFeesClaim.vue")
            },
            {
                path: '/pettyCash',
                name: 'Petty Cash List',
                component: () => import("@/views/claim/pettyCash/PettyCashDetails.vue")
            },
            {
                path: '/xbrl',
                name: 'XBRL Reporting',
                component: () => import("@/views/secretarial/CompanyList.vue")
            },
            {
                path: '/purchaseInvoice',
                name: 'Purchase Invoice',
                component: () => import("@/views/procurement/purchaseInvoice/PurchaseInvoiceList.vue")
            },
            {
                path: '/paymentVoucher',
                name: 'Payment Voucher',
                component: () => import("@/views/procurement/paymentVoucher/PaymentVoucherList.vue")
            },
            {
                path: '/purchaseOrder',
                name: 'Purchase Order',
                component: () => import("@/views/procurement/purchaseOrder/PurchaseOrderList.vue")
            },
            {
                path: '/purchaseRequisition',
                name: 'Purchase Requisition',
                component: () => import("@/views/procurement/purchaseRequisition/PurchaseRequisitionList.vue")
            },
            {
                path: '/reimbursementClaim',
                name: 'Reimbursement Claim',
                component: () => import("@/views/procurement/claim/ReimbursementClaimList.vue")
            },
            {
                path: '/workManagement',
                name: 'Work Management',
                component: () => import("@/views/workManagement/WorkManagementMenu.vue")
            },
            {
                path: '/project',
                name: 'Project List',
                component: () => import("@/views/roster/ProjectList.vue")
            },
            {
                path: '/customer',
                name: 'Customer List',
                component: () => import("@/views/sales/customer/CustomerList.vue")
            },
            {
                path: '/customerUser',
                name: 'Customer User List',
                component: () => import("@/views/sales/customer/CustomerUserList.vue")
            },
            {
                path: '/salesContract',
                name: 'Sales Contract List',
                component: () => import("@/views/sales/salesContract/SalesContractList.vue")
            },
            {
                path: '/feedback',
                name: 'Feedback',
                component: () => import("@/views/sales/feedback/FeedbackList.vue")
            },
            {
                path: '/exchangePayment',
                name: 'Exchange Payment',
                component: () => import("@/views/sales/exchangePayment/ExchangePaymentList.vue")
            },
            {
                path: '/mcstInvoice',
                name: 'MCST Invoice Management',
                component: () => import("@/views/sales/mcstInvoice/McstInvoiceList.vue")
            },
            {
                path: '/setting/serviceFee',
                name: 'Service Fee Setting',
                component: () => import("@/views/setup/setting/serviceFee/ServiceFeeSettingDashboard.vue")
            },
            {
                path: '/setting/serviceFee/terms',
                name: 'Service Fee Terms And Condition Setup',
                component: () => import("@/views/setup/setting/serviceFee/ServiceFeeInvoiceSetup.vue")
            },
            {
                path: '/setting/serviceFee/calculation',
                name: 'Service Fee Type Setup',
                component: () => import("@/views/setup/setting/serviceFee/ServiceFeeCalculationSetup.vue")
            },
            {
                path: '/setting',
                name: 'Setting',
                component: () => import("@/views/setup/setting/SettingDashboard.vue")
            },
            {
                path: '/setting/procurement',
                name: 'Procurement Setting',
                component: () => import("@/views/setup/setting/ProcurementSetting.vue")
            },
            {
                path: '/setting/payroll',
                name: 'Payroll Setting',
                component: () => import("@/views/setup/setting/PayrollSetting.vue")
            },
            {
                path: '/setting/sales',
                name: 'Sales Setting',
                component: () => import("@/views/setup/setting/SalesSetting.vue")
            },
            {
                path: '/vendorWaiveReason',
                name: 'Vendor Waive Reason',
                component: () => import("@/views/setup/setting/procurement/VendorWaiveReasonList.vue")
            },
            {
                path: '/vendorItem',
                name: 'Vendor Purchase Item',
                component: () => import("@/views/setup/setting/procurement/VendorPurchaseItemList.vue")
            },
            {
                path: '/vendorItemCategory',
                name: 'Vendor Purchase Item Category',
                component: () => import("@/views/setup/setting/procurement/VendorItemCategoryList.vue")
            },
            {
                path: '/accountCode',
                name: 'Account Code',
                component: () => import("@/views/setup/setting/procurement/AccountCodeList.vue")
            },
            {
                path: '/emailTemplate',
                name: 'Email Template',
                component: () => import("@/views/setup/setting/procurement/EmailTemplateList.vue")
            },
            {
                path: '/payrollAdjustment',
                name: 'Payroll Adjustment',
                component: () => import("@/views/setup/setting/payroll/PayrollAdjustmentList.vue")
            },
            {
                path: '/feedbackTarget',
                name: 'Feedback Target',
                component: () => import("@/views/setup/setting/sales/FeedbackTargetList.vue")
            },
            {
                path: '/exchangePaymentRefundDetails',
                name: 'Exchange Payment Refund Details',
                component: () => import("@/views/setup/setting/sales/ExchangePaymentRefundDetailsList.vue")
            },
            {
                path: '/feedbackProvider',
                name: 'Feedback Provider',
                component: () => import("@/views/setup/setting/sales/FeedbackProviderList.vue")
            },
            {
                path: '/feedbackSource',
                name: 'Feedback Source',
                component: () => import("@/views/setup/setting/sales/FeedbackSourceList.vue")
            },
            {
                path: '/feedbackCategory',
                name: 'Feedback Category',
                component: () => import("@/views/setup/setting/sales/FeedbackCategoryList.vue")
            },

            ...financeRouters
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from) => {
    if (to.meta.nonAuth) {
        return;
    }
    if (to.name !== "login" && !sessionStorage.getItem("login")) {
        return {name: "login"};
    }
});

export default router;
